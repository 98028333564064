
import EcomLanscapeOrientationWarning from "./common-components/EcomLanscapeOrientationWarning.vue";
import { ref } from "vue";

export default {
	name: "LayoutDefault",
	components: {
		EcomLanscapeOrientationWarning,
	},
	setup() {
		const contentReady = ref(false);

		const setContentReady = () => {
			contentReady.value = true;
		};

		const getContentReadyFuction = () => {
			return setContentReady;
		};

		document.addEventListener("DOMContentLoaded", setContentReady);
		return {
			getContentReadyFuction, // Just for unit-testing
			setContentReady, // Just for unit-testing
			contentReady,
		};
	},
};
