export enum EcomCheckoutScreen {
	PaymentConfirmation = 1,
	PaymentSuccess,
	PaymentTimeout,
	PaymentAttemptsLimitRiched,
	PaymentCancel,
	NoPaymentMethodAvailable,
	UserCards,
	ReceiptSendSuccess,
	ReceiptSendError,
}
