
import Platform from 'quasar/src/plugins/platform/Platform.js';;
import { defineComponent, onUpdated, ref, Ref } from "vue";
import Container from "@/components/Container/Container.vue";
import CheckoutHeader from "@/components/Header/Header.vue";

export default defineComponent({
	name: "EcomLanscapeOrientationWarning",
	components: {
		Container,
		CheckoutHeader,
	},
	setup() {
		let platformObject: typeof Platform = Platform;
		let windowObject: Window = window;

		const isDeviceRotationMessageVisible: Ref<boolean> = ref(false);

		/* istanbul ignore next */
		const setupMocks = (platformMock: typeof Platform) => {
			platformObject = platformMock;
		};

		onUpdated(() => {
			handleComponentUpdate();
		});

		const handleComponentUpdate = () => {
			const isMobile: boolean = !platformObject.is.desktop;
			const isPortrait: boolean =
				windowObject.matchMedia("(orientation: portrait), (max-aspect-ratio: 121/80)").matches === true;

			isDeviceRotationMessageVisible.value = isMobile && !isPortrait;
		};

		return {
			setupMocks,
			handleComponentUpdate, // Just for unit-testing
			isDeviceRotationMessageVisible,
		};
	},
});
