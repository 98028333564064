/* istanbul ignore file */
export default class ApplePayConfigurator {
	public static initialize(rootElementId: string) {
		const aPayButtonRootElement = document.getElementById(rootElementId)!;

		if (aPayButtonRootElement) {
			aPayButtonRootElement.setAttribute("id", "ckoApplePay");
			aPayButtonRootElement.setAttribute("buttonstyle", "black");
			aPayButtonRootElement.setAttribute("lang", "uk");
		}
	}

	public static createApplePaySession(merchantName: string, amount: number, version: number): any {
		const paymentRequest = {
			total: {
				label: merchantName,
				amount: amount,
			},
			countryCode: "UA",
			currencyCode: "UAH",
			merchantCapabilities: ["supports3DS"],
			supportedNetworks: ["masterCard", "visa"],
		};

		// @ts-expect-error
		if (window.ApplePaySession) {
			// @ts-expect-error
			return new window.ApplePaySession(version, paymentRequest);
		}
	}
}
