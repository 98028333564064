export default class GPayTransactionInfo {
	public countryCode?: string;
	public currencyCode?: string;
	public totalPriceStatus?: string;
	public totalPrice?: string;

	constructor(data?: GPayTransactionInfo) {
		Object.assign(this, data);
	}
}
