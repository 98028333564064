import BrowserInfo from "../BrowserInfo";
import CardDetails from "../CardDetails";
import BaseApproveRequest from "./BaseApproveRequest";

export default class CardPayApproveRequest extends BaseApproveRequest {
	public cardDetails?: CardDetails;
	public browserInfo?: BrowserInfo;

	constructor(instance?: CardPayApproveRequest) {
		super(instance);
		Object.assign(this, instance);
	}
}
