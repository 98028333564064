import GPayApiVersion from "@/models/googlePay/GPayApiVersion";
import GPayCardPaymentMethod from "@/models/googlePay/GPayCardPaymentMethod";
import MerchantInfo from "@/models/googlePay/GPayMerchantInfo";
import GPayMethod from "@/models/googlePay/GPayMethod";
import GPayPaymentDataRequest from "@/models/googlePay/GPayPaymentDataRequest";
import GPayReadyToPayRequest from "@/models/googlePay/GPayReadyToPayRequest";
import GPayTransactionInfo from "@/models/googlePay/GPayTransactionInfo";
import { PlatformDetector } from "./PlatformDetector";

/* istanbul ignore file */
export default class GooglePayConfigurator {
	private _paymentsClient: any;
	private _gPayButtonRootElement?: HTMLElement;
	private _baseRequest?: GPayApiVersion;
	private _allowedCardNetworks?: Array<string>;
	private _allowedCardAuthMethods?: Array<string>;
	private _tokenizationSpecification: GPayMethod;
	private _baseCardPaymentMethod: GPayMethod;
	private _cardPaymentMethod?: GPayCardPaymentMethod;
	private _countryCode?: string;
	private _currencyCode?: string;
	private _totalPriceStatus?: string;
	private _totalPrice?: string;
	private _merchantName?: string;
	private _callback: any;
	private _gpayRootElementId: string;

	constructor(rootElementId: string, gatewayMerchantId: number) {
		this._gPayButtonRootElement = document.getElementById(rootElementId)!;
		this._baseRequest = new GPayApiVersion({ apiVersion: 2, apiVersionMinor: 0 });
		this._allowedCardNetworks = ["MASTERCARD", "VISA"];
		this._allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
		this._gpayRootElementId = rootElementId;

		this._tokenizationSpecification = new GPayMethod({
			type: "PAYMENT_GATEWAY",
			parameters: {
				gateway: "bankvostok",
				gatewayMerchantId: gatewayMerchantId.toString(),
			},
		});

		this._baseCardPaymentMethod = new GPayMethod({
			type: "CARD",
			parameters: {
				allowedAuthMethods: this._allowedCardAuthMethods,
				allowedCardNetworks: this._allowedCardNetworks,
			},
		});

		this._cardPaymentMethod = new GPayCardPaymentMethod(this._baseCardPaymentMethod, this._tokenizationSpecification);
		this._paymentsClient = null;
	}

	public initialize(paymentsClient: any, callback: (token: string) => void): void {
		this._paymentsClient = paymentsClient;
		this._callback = callback;
		paymentsClient
			.isReadyToPay(this.getGoogleIsReadyToPayRequest())
			.then(response => {
				if (response.result) {
					const gpayButtonContainer = document.getElementById(this._gpayRootElementId);
					gpayButtonContainer!.style.display = "block";
					this.addGooglePayButton();
					this.prefetchGooglePaymentData();
				}
			})
			.catch(function (err) {
				console.error(err);
			});
	}

	public set countryCode(value: string) {
		this._countryCode = value;
	}

	public set currencyCode(value: string) {
		this._currencyCode = value;
	}

	public set totalPriceStatus(value: string) {
		this._totalPriceStatus = value;
	}

	public set totalPrice(value: string) {
		this._totalPrice = value;
	}

	public set merchantName(value: string) {
		this._merchantName = value;
	}

	private getGoogleIsReadyToPayRequest(): GPayReadyToPayRequest {
		return new GPayReadyToPayRequest(this._baseRequest, [this._baseCardPaymentMethod]);
	}

	private getGooglePaymentDataRequest(): GPayPaymentDataRequest {
		const request = new GPayPaymentDataRequest(this._baseRequest);
		request.allowedPaymentMethods = [this._cardPaymentMethod!];
		request.transactionInfo = this.getGoogleTransactionInfo();
		request.merchantInfo = new MerchantInfo({
			merchantName: this._merchantName,
			merchantId: "BCR2DN4T4CCZD3IR",
			merchantOrigin: "checkout.ecom.vostok.bank",
		});

		return request;
	}

	private addGooglePayButton() {
		const platform = PlatformDetector.GetCurrentPatform();

		const gPayButton = this._paymentsClient.createButton({
			buttonLocale: "uk",
			buttonSizeMode: "fill",
			buttonType: navigator.userAgent.indexOf("Chrome") != -1 ? "buy" : "plain",
			onClick: () => this.onGooglePaymentButtonClicked(),
			allowedPaymentMethods: [this._baseCardPaymentMethod],
		});

		if (this._gPayButtonRootElement) {
			this._gPayButtonRootElement?.appendChild(gPayButton);
		}
	}

	private getGoogleTransactionInfo(): GPayTransactionInfo {
		return new GPayTransactionInfo({
			countryCode: this._countryCode,
			currencyCode: this._currencyCode,
			totalPriceStatus: this._totalPriceStatus,
			totalPrice: this._totalPrice,
		});
	}

	private prefetchGooglePaymentData(): void {
		const paymentDataRequest = this.getGooglePaymentDataRequest();
		paymentDataRequest.transactionInfo = {
			totalPriceStatus: "NOT_CURRENTLY_KNOWN",
			currencyCode: "UAH",
		};
		const paymentsClient = this._paymentsClient;
		paymentsClient.prefetchPaymentData(paymentDataRequest);
	}

	private onGooglePaymentButtonClicked(): void {
		const paymentDataRequest = this.getGooglePaymentDataRequest();
		paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();
		const paymentsClient = this._paymentsClient;
		paymentsClient
			.loadPaymentData(paymentDataRequest)
			.then(paymentData => {
				this.processPayment(paymentData);
			})
			.catch(function (err) {
				console.error(err);
			});
	}

	private processPayment(paymentData: any) {
		const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
		this._callback(paymentToken);
	}
}
