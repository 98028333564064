import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "rotate-device-message-container" }
const _hoisted_2 = { class: "absolute-center full-width" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkout_header = _resolveComponent("checkout-header")!
  const _component_container = _resolveComponent("container")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_checkout_header)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: require('@/assets/images/ecom-rotate-device.svg'),
          id: "device-rotate-icon",
          alt: "Перевернутий девайс",
          width: "126",
          height: "128",
          class: "q-mb-lg"
        }, null, 8, _hoisted_3),
        _cache[0] || (_cache[0] = _createElementVNode("p", {
          id: "rotate-device-message-text",
          class: "q-mb-none q-px-md"
        }, "У вертикальному положенні зручніше", -1))
      ])
    ], 512), [
      [_vShow, _ctx.isDeviceRotationMessageVisible]
    ]),
    _withDirectives(_createVNode(_component_q_page_container, { id: "page-content" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 512), [
      [_vShow, !_ctx.isDeviceRotationMessageVisible]
    ])
  ], 64))
}