import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/Checkout/:id",
		name: "Checkout",
		component: () => import(/* webpackChunkName: "main" */ "@/views/EcomPayCheckout/EcomCheckout.vue"),
	},
	{
		path: "/EcomPayConfirm/:id",
		name: "EcomPayConfirm",
		component: () => import(/* webpackChunkName: "main" */ "@/views/EcomPayCheckout/EcomCheckout.vue"),
	},
	{
		path: "/Checkout/error",
		name: "EcomCheckoutErrorPage",
		component: () => import(/* webpackChunkName: "main" */ "@/views/EcomPayCheckout/EcomCheckoutErrorPage.vue"),
	},
	{
		path: "/:catchAll(.*)",
		name: "EcomCheckoutPageNotFound",
		component: () => import(/* webpackChunkName: "main" */ "@/views/EcomPayCheckout/EcomCheckoutPageNotFound.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
