import GPayApiVersion from "@/models/googlePay/GPayApiVersion";
import GPayMethod from "@/models/googlePay/GPayMethod";

export default class GPayReadyToPayRequest extends GPayApiVersion {
	public allowedPaymentMethods?: Array<GPayMethod>;

	constructor(apiVersion?: GPayApiVersion, allowedPaymentMethods?: Array<GPayMethod>) {
		super(apiVersion);
		this.allowedPaymentMethods = allowedPaymentMethods;
	}
}
