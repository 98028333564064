
import { defineComponent, ref, Ref } from "vue";

export default defineComponent({
	name: "CheckoutHeader",
	setup() {
		const isOpenPopup: Ref<boolean> = ref(false);

		const changePopupState = (value: boolean) => {
			isOpenPopup.value = value;
		};

		return { isOpenPopup, changePopupState };
	},
});
