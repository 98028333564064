import GPayApiVersion from "@/models/googlePay/GPayApiVersion";
import GPayCardPaymentMethod from "@/models/googlePay/GPayCardPaymentMethod";
import GPayTransactionInfo from "@/models/googlePay/GPayTransactionInfo";
import GPayMerchantInfo from "@/models/googlePay/GPayMerchantInfo";

export default class GPayPaymentDataRequest extends GPayApiVersion {
	public allowedPaymentMethods?: Array<GPayCardPaymentMethod>;
	public transactionInfo?: GPayTransactionInfo;
	public merchantInfo?: GPayMerchantInfo;

	constructor(apiVersion?: GPayApiVersion) {
		super(apiVersion);
	}
}
