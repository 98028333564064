export default class BrowserInfo {
	public browserAcceptHeader?: string;
	public browserColorDepth?: number;
	public browserJavaEnabled?: string;
	public browserJavascriptEnabled?: string;
	public browserLanguage?: string;
	public browserScreenHeight?: number;
	public browserScreenWidth?: number;
	public browserTZ?: number;
	public browserUserAgent?: string;

	constructor(instance?: BrowserInfo) {
		Object.assign(this, instance);
	}
}
