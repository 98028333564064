import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import router from "./router";
import store from "./store";
import { i18n } from "@/i18n";

const app = createApp(App).use(router).use(i18n).use(store).use(Quasar, quasarUserOptions);

app.directive("focus", (el, binding) => {
	if (binding.value) {
		el.focus();
	}
});

app.mount("#app");
