import { EcomCheckoutScreen } from "@/enums/EcomCheckoutScreen";
import { ErrorCode } from "@/enums/ErrorCode";
import router from "@/router";
import * as CardPayMutations from "@/store/constants//Mutations";
import * as CommonActions from "@/store/constants/Common/Actions";
import * as CommonGetters from "@/store/constants/Common/Getters";
import * as CommonMutations from "@/store/constants/Common/Mutations";
import * as SignalR from "@microsoft/signalr";
import { ActionContext, createStore } from "vuex";
import { EcomCheckoutModule } from "./modules/EcomCheckoutModule";

export interface IRootState {
	isWebSocketInitialized: boolean;
	connection: any | null;
}

/* istanbul ignore file */
// TODO: research SignalR connection testing
export default createStore({
	state: {
		isWebSocketInitialized: false,
		connection: null as any | null,
	} as IRootState,
	getters: {
		[CommonGetters.COMMON__GET_WEB_SOCKET_CONNECTION](state: IRootState): void {
			return state.connection;
		},
	},
	mutations: {
		[CommonMutations.COMMON__SET_WEB_SOCKET_CONNECTION](state: IRootState, connection: any): void {
			state.connection = connection;
		},
	},
	actions: {
		[CommonActions.COMMON__CLOSE_WEB_SOCKET_CONNECTION](
			context: ActionContext<IRootState, IRootState>,
			checkoutId: string
		): void {
			const connection = context.getters[CommonGetters.COMMON__GET_WEB_SOCKET_CONNECTION];
			connection.stop().then(() => console.info("WebSocket connection closed"));
		},
		[CommonActions.COMMON__INIT_WEB_SOCKET](context: ActionContext<IRootState, IRootState>, checkoutId: string): void {
			try {
				const connection = new SignalR.HubConnectionBuilder()
					.withUrl(`/ecomm-pay?checkoutId=${checkoutId}`)
					.withAutomaticReconnect()
					.build();

				connection.keepAliveIntervalInMilliseconds = 60000;

				connection.on("paymentConfirmationResult", (errorCode: ErrorCode) => {
					if (errorCode === ErrorCode.Success) {
						context.commit(CardPayMutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentSuccess);
						context.commit(CardPayMutations.ECOM__SET_PAYMENT_ERROR, null);
					} else {
						context.commit(CardPayMutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						context.commit(CardPayMutations.ECOM__SET_PAYMENT_ERROR, errorCode);
						context.commit(CardPayMutations.ECOM__SET_IS_CONFIRMATION_WAITING, false);
					}
				});
				connection.on("paymentConfirmationWaiting", (isPaymentConfirmationWaiting: boolean) => {
					context.commit(CardPayMutations.ECOM__SET_IS_CONFIRMATION_WAITING, isPaymentConfirmationWaiting);
				});
				connection.on("cardPayCriticalError", (message: string) => {
					context.commit(CardPayMutations.ECOM__SET_PAYMENT_ERROR, null);
					console.error(message);
					router.push("/Checkout/error");
				});

				connection.start();

				context.commit(CommonMutations.COMMON__SET_WEB_SOCKET_CONNECTION, connection);
			} catch (e) {
				console.error(e);
			}
		},
	},
	modules: {
		EcomCheckoutModule,
	},
});
