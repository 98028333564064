export const ECOM__GET_CURRENT_CHECKOUT_SCREEN = "ECOM__GET_CURRENT_CHECKOUT_SCREEN";
export const ECOM__GET_CHECKOUT_DATA = "ECOM__GET_CHECKOUT_DATA";
export const ECOM__GET_MERCHANT_INFO = "ECOM__GET_MERCHANT_INFO";
export const ECOM__GET_IS_APP_WAITING = "ECOM__GET_IS_APP_WAITING";
export const ECOM__GET_IS_CONFIRMATION_WAITING = "ECOM__GET_IS_CONFIRMATION_WAITING";
export const ECOM__GET_PAYMENT_ERROR = "ECOM__GET_PAYMENT_ERROR";
export const ECOM__GET_APPLE_PAY_SESSION_OBJECT = "ECOM__GET_APPLE_PAY_SESSION_OBJECT";
export const ECOM__GET_CHECKOUT_CONFIGURATION = "ECOM__GET_CHECKOUT_CONFIGURATION";
export const ECOM__GET_AVAILABLE_PAYMENT_ATTEMPTS = "ECOM__GET_AVAILABLE_PAYMENT_ATTEMPTS";
export const ECOM__GET_IS_GOOGLE_PAY_METHOD_READY = "ECOM__GET_IS_GOOGLE_PAY_METHOD_READY";
export const ECOM__GET_IS_BVR_PAY_METHOD_READY = "ECOM__GET_IS_BVR_PAY_METHOD_READY";
export const ECOM__GET_IS_APPLE_PAY_METHOD_READY = "ECOM__GET_IS_APPLE_PAY_METHOD_READY";
export const ECOM__GET_IS_ALL_PAYMENT_METHODS_READY_TO_USE = "ECOM__GET_IS_ALL_PAYMENT_METHODS_READY_TO_USE";
export const ECOM__CAN_SHOW_TEST_CARDS_INFO = "ECOM__CAN_SHOW_TEST_CARDS_INFO";
export const ECOM__GET_IS_ORDER_NUMBER_HIDDEN = "ECOM__GET_IS_ORDER_NUMBER_HIDDEN";
