import BrowserInfo from "../BrowserInfo";
import BaseApproveRequest from "./BaseApproveRequest";

export default class GooglePayApproveRequest extends BaseApproveRequest {
	public googlePayToken?: string;
	public browserInfo?: BrowserInfo;

	constructor(instance?: GooglePayApproveRequest) {
		super(instance);
		Object.assign(this, instance);
	}
}
