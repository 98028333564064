import { EcomCheckoutScreen } from "@/enums/EcomCheckoutScreen";
import { ErrorCode } from "@/enums/ErrorCode";
import { ThreeDSecure } from "@/enums/ThreeDSecure";
import { ThreeDSecureFlow } from "@/enums/ThreeDSecureFlow";
import { IBvrSdk } from "@/interfaces/IBvrSdk";
import AppleSessionValidationRequest from "@/models/ApplePaySessionValidation/AppleSessionValidationRequest";
import AppleSessionValidationResponse from "@/models/ApplePaySessionValidation/AppleSessionValidationResponse";
import EcomCheckoutCache from "@/models/cache/EcomCheckoutCache";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import CardDetails from "@/models/CardDetails";
import BvrPayConfiguration from "@/models/configurations/BvrPayConfiguration";
import GooglePayConfiguration from "@/models/configurations/GooglePayConfiguration";
import EcomApplicationCache from "@/models/EcomApplicationCache";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";
import HtmlRootElementsCollection from "@/models/HtmlRootElementsCollection";
import ActionLogRequest from "@/models/requests/ActionLogRequest";
import ApplePayApprovePayload from "@/models/requests/ApplePayApprovePayload";
import ApplePayApproveResponse from "@/models/requests/ApplePayApproveResponse";
import CardPayData from "@/models/requests/CardPayData";
import CardPaySendReceiptPayload from "@/models/requests/CardPaySendReceiptPayload";
import GooglePayRequest from "@/models/requests/GooglePayRequest";
import ApplePayResponse from "@/models/responses/ApplePayResponse";
import CardInformationResponse from "@/models/responses/CardInformationResponse";
import CardPayResponse from "@/models/responses/CardPayResponse";
import CheckoutConfiguration from "@/models/responses/CheckoutConfiguration";
import GooglePayApproveResponse from "@/models/responses/GooglePayApproveResponse";
import GooglePayResponse from "@/models/responses/GooglePayResponse";
import router from "@/router";
import { IRootState } from "@/store";
import * as Getters from "@/store/constants//Getters";
import * as Mutations from "@/store/constants//Mutations";
import * as Actions from "@/store/constants/Actions";
import * as CommonActions from "@/store/constants/Common/Actions";
import ApplePayConfigurator from "@/utilities/ApplePayConfigurator";
import BrowserInfoHelper from "@/utilities/BrowserInfoHelper";
import GooglePayConfigurator from "@/utilities/GooglePayConfigurator";
import { HttpRequestDispatcher, HttpRequestMethod } from "@/utilities/HttpRequestDispatcher";
import UrlRedirectResolver from "@/utilities/UrlRedirectResolver";
import { AxiosResponse } from "axios";
import { ActionContext } from "vuex";

const APPLE_PAY_VERSION = 3;

declare let EcomSDK;

/* istanbul ignore next */
export default class SdkLoader {
	/* istanbul ignore next */
	public static loadCdnSdk =
		/* istanbul ignore next */
		(): IBvrSdk => new EcomSDK.BvrPay("bvr-pay", true);
}

export interface IEcomCheckoutState {
	checkoutData: EcomCheckoutData | null;
	merchantInfo: MerchantInfoCache | null;
	paymentError: ErrorCode | null;
	currentCheckoutScreen: EcomCheckoutScreen;
	isApplicationWaiting: boolean;
	availablePaymentAttempts: number | null;
	isConfirmationWaiting: boolean;
	applePaySessionObject: any | null;
	checkoutConfiguration: CheckoutConfiguration | null;
	isGooglePayReady: boolean;
	isBvrPayMethodReady: boolean;
	isApplePayReady: boolean;
	isOrderNumberHidden: boolean;
}

export const EcomCheckoutModule = {
	documentHrefMock: null,
	state: {
		merchantInfo: null,
		checkoutData: null,
		currentCheckoutScreen: EcomCheckoutScreen.PaymentConfirmation,
		isApplicationWaiting: false,
		isConfirmationWaiting: false,
		paymentError: null,
		checkoutConfiguration: null,
		isGooglePayReady: false,
		isBvrPayMethodReady: false,
		isApplePayReady: false,
		isOrderNumberHidden: false,
	} as IEcomCheckoutState,
	getters: {
		[Getters.ECOM__GET_CURRENT_CHECKOUT_SCREEN]: (state: IEcomCheckoutState): EcomCheckoutScreen =>
			state.currentCheckoutScreen,
		[Getters.ECOM__GET_CHECKOUT_DATA]: (state: IEcomCheckoutState): EcomCheckoutData | null => state.checkoutData,
		[Getters.ECOM__GET_MERCHANT_INFO]: (state: IEcomCheckoutState): MerchantInfoCache | null => state.merchantInfo,
		[Getters.ECOM__GET_IS_APP_WAITING]: (state: IEcomCheckoutState): boolean => state.isApplicationWaiting,
		[Getters.ECOM__GET_PAYMENT_ERROR]: (state: IEcomCheckoutState): number | null => state.paymentError,
		[Getters.ECOM__GET_IS_CONFIRMATION_WAITING]: (state: IEcomCheckoutState): boolean | null =>
			state.isConfirmationWaiting,
		[Getters.ECOM__GET_APPLE_PAY_SESSION_OBJECT]: (state: IEcomCheckoutState): any | null =>
			state.applePaySessionObject,
		[Getters.ECOM__GET_CHECKOUT_CONFIGURATION]: (state: IEcomCheckoutState): CheckoutConfiguration | null =>
			state.checkoutConfiguration,
		[Getters.ECOM__GET_AVAILABLE_PAYMENT_ATTEMPTS]: (state: IEcomCheckoutState): Number | null =>
			state.availablePaymentAttempts,
		[Getters.ECOM__GET_IS_BVR_PAY_METHOD_READY]: (state: IEcomCheckoutState): boolean => state.isBvrPayMethodReady,
		[Getters.ECOM__GET_IS_APPLE_PAY_METHOD_READY]: (state: IEcomCheckoutState): boolean => state.isApplePayReady,
		[Getters.ECOM__GET_IS_GOOGLE_PAY_METHOD_READY]: (state: IEcomCheckoutState): boolean => state.isGooglePayReady,
		[Getters.ECOM__GET_IS_ALL_PAYMENT_METHODS_READY_TO_USE]: (state: IEcomCheckoutState): boolean => {
			return state.isApplePayReady && state.isGooglePayReady && state.isBvrPayMethodReady;
		},
		[Getters.ECOM__CAN_SHOW_TEST_CARDS_INFO]: (state: IEcomCheckoutState): boolean => {
			const checkoutConfiguration: CheckoutConfiguration = state.checkoutConfiguration!;

			if (!checkoutConfiguration) {
				return false;
			}
			return JSON.parse(checkoutConfiguration.showTestCards!);
		},
		[Getters.ECOM__GET_IS_ORDER_NUMBER_HIDDEN]: (state: IEcomCheckoutState): boolean => state.isOrderNumberHidden,
	},
	mutations: {
		[Mutations.ECOM__SET_CHECKOUT_CONFIGURATION](
			state: IEcomCheckoutState,
			configuration: CheckoutConfiguration
		): void {
			state.checkoutConfiguration = configuration;
		},
		[Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN](
			state: IEcomCheckoutState,
			currentCheckoutScreen: EcomCheckoutScreen
		): void {
			state.currentCheckoutScreen = currentCheckoutScreen;
		},
		[Mutations.ECOM__SET_MERCHANT_INFO](state: IEcomCheckoutState, merchantInfo: MerchantInfoCache): void {
			state.merchantInfo = merchantInfo;
		},
		[Mutations.ECOM__SET_CHECKOUT_DATA](state: IEcomCheckoutState, checkoutData: EcomCheckoutData): void {
			state.checkoutData = checkoutData;
		},
		[Mutations.ECOM__SET_IS_APP_WAITING](state: IEcomCheckoutState, isApplicationWaiting: boolean): void {
			state.isApplicationWaiting = isApplicationWaiting;
		},
		[Mutations.ECOM__SET_PAYMENT_ERROR](state: IEcomCheckoutState, paymentErrorCode: ErrorCode): void {
			state.paymentError = paymentErrorCode;
		},
		[Mutations.ECOM__SET_IS_CONFIRMATION_WAITING](state: IEcomCheckoutState, isWaiting: boolean): void {
			state.isConfirmationWaiting = isWaiting;
		},
		[Mutations.ECOM__SET_APPLE_PAY_SESSION_OBJECT](state: IEcomCheckoutState, applePaySessionObject: any): void {
			state.applePaySessionObject = applePaySessionObject;
		},
		[Mutations.ECOM__SET_AVAILABLE_PAYMENT_ATTEMPTS](
			state: IEcomCheckoutState,
			availablePaymentAttempts: number
		): void {
			state.availablePaymentAttempts = availablePaymentAttempts;
		},
		[Mutations.ECOM__SET_IS_BVR_PAY_METHOD_READY](state: IEcomCheckoutState, isReady: boolean): void {
			state.isBvrPayMethodReady = isReady;
		},
		[Mutations.ECOM__SET_IS_GOOGLE_PAY_METHOD_READY](state: IEcomCheckoutState, isReady: boolean): void {
			state.isGooglePayReady = isReady;
		},
		[Mutations.ECOM__SET_IS_APPLE_PAY_METHOD_READY](state: IEcomCheckoutState, isReady: boolean): void {
			state.isApplePayReady = isReady;
		},
		[Mutations.ECOM__SET_IS_ORDER_NUMBER_HIDDEN](state: IEcomCheckoutState, isHidden: boolean): void {
			state.isOrderNumberHidden = isHidden;
		},
	},
	actions: {
		[Actions.ECOM__LOAD_CHECKOUT_CONFIGURATION](context: ActionContext<IEcomCheckoutState, IRootState>): Promise<void> {
			const requestUrl = "/api/Options";

			return HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl);
		},
		[Actions.ECOM__PERFORM_APPLE_PAY_PAYMENT](context: ActionContext<IEcomCheckoutState, IRootState>) {
			context.dispatch(Actions.ECOM__CREATE_APPLE_PAY_SESSION);
			context.commit(Mutations.ECOM__SET_IS_APP_WAITING, true);
			const applePaySession = context.getters[Getters.ECOM__GET_APPLE_PAY_SESSION_OBJECT];

			if (!applePaySession) {
				console.error("ApplePay session is null");
				context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				return;
			}

			applePaySession.onvalidatemerchant = (event: any) => {
				context.dispatch(Actions.ECOM__VALIDATE_APPLE_PAY_SESSION, event.validationURL);
			};
			applePaySession.onpaymentauthorized = (data: any) => {
				context.dispatch(Actions.ECOM__SEND_APPLE_PAY_APPROVE_REQUEST, data);
			};
			applePaySession.oncancel = () => {
				context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				context.commit(Mutations.ECOM__SET_IS_CONFIRMATION_WAITING, false);
				return;
			};
			applePaySession.begin();
		},
		async [Actions.ECOM__VALIDATE_APPLE_PAY_SESSION](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			validationUrl: string
		) {
			const requestURL = "/api/ApplePaySessionValidation";
			const applePaySession = context.getters[Getters.ECOM__GET_APPLE_PAY_SESSION_OBJECT];
			const merchantInfo: MerchantInfoCache = context.getters[Getters.ECOM__GET_MERCHANT_INFO];
			const appleSessionValidationRequest = new AppleSessionValidationRequest({
				sessionValidationUrl: validationUrl,
				merchantName: merchantInfo.merchantName,
			});

			await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestURL, appleSessionValidationRequest)
				.then((response: any) => {
					const appleSessionValidationResponse: AppleSessionValidationResponse = response.data;
					if (!appleSessionValidationResponse) {
						throw "Apple pay session validation response is null";
					}
					if (appleSessionValidationResponse.errorCode !== ErrorCode.Success) {
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, appleSessionValidationResponse.errorCode);
						context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						console.error(appleSessionValidationResponse.errorMessage);
						return;
					}
					applePaySession.oncancel = () => {
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
						context.commit(Mutations.ECOM__SET_IS_CONFIRMATION_WAITING, false);
						return;
					};
					applePaySession.completeMerchantValidation(appleSessionValidationResponse.session);
				})
				.catch((error: any) => {
					router.push("/Checkout/error");
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
					/* istanbul ignore next */
					process.env.JEST_WORKER_ID || console.error(error);
				});
		},
		async [Actions.ECOM__SEND_APPLE_PAY_APPROVE_REQUEST](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			appleAuthorizationTokenObject: any
		) {
			const requestURL = "/api/ApplePayApprove";
			const stringifiedApplePayTokenData = JSON.stringify(appleAuthorizationTokenObject.payment.token);
			const applePaySession = context.getters[Getters.ECOM__GET_APPLE_PAY_SESSION_OBJECT];
			const browserInfo = await BrowserInfoHelper.getBrowserInfo();
			const checkoutData: EcomCheckoutData = context.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const request: ApplePayApprovePayload = new ApplePayApprovePayload({
				applePayToken: stringifiedApplePayTokenData,
				browserInfo: browserInfo,
			});
			request.browserInfo = await BrowserInfoHelper.getBrowserInfo();
			Object.assign(request, checkoutData);

			await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestURL, request)
				.then((response: any) => {
					if (!response || !response.data) {
						console.error("Apple pay approve response or response data is null");
						router.push("/Checkout/error");
						return;
					}

					const data: ApplePayResponse = response.data;
					const applePayApproveResponse: ApplePayApproveResponse = data.applePayApproveResponse!;

					if (!applePayApproveResponse) {
						console.error("ApplePay approve response data is null");
						router.push("/Checkout/error");
						return;
					}

					if (applePayApproveResponse!.errorCode !== ErrorCode.Success) {
						if (applePayApproveResponse!.errorCode === ErrorCode.MaxApproveAttemptsExceeded) {
							context.commit(
								Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN,
								EcomCheckoutScreen.PaymentAttemptsLimitRiched
							);
						} else {
							context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						}
						context.dispatch(Actions.ECOM__COMPLETE_APPLE_PAYMENT, false);
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, applePayApproveResponse.errorCode);
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
						applePaySession.abort();
						return;
					}

					context.commit(Mutations.ECOM__SET_AVAILABLE_PAYMENT_ATTEMPTS, data.availablePaymentAttempts);

					if (
						applePayApproveResponse.check3DS === ThreeDSecure.V2 &&
						data.applePayApproveResponse!.flow === ThreeDSecureFlow.Challenge
					) {
						context.dispatch(CommonActions.COMMON__CLOSE_WEB_SOCKET_CONNECTION);
						context.dispatch(Actions.ECOM_GO_TO_LINK, applePayApproveResponse!.acsUrl);
					}
					// @ts-expect-error
					applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
				})
				.catch(e => {
					console.error(e);
					applePaySession.abort();
					router.push("/Checkout/error");
				})
				.finally(() => {
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				});
		},
		[Actions.ECOM__CREATE_APPLE_PAY_SESSION](context: ActionContext<IEcomCheckoutState, IRootState>) {
			const merchantInfo: MerchantInfoCache = context.getters[Getters.ECOM__GET_MERCHANT_INFO];
			const checkoutData: EcomCheckoutData = context.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const applePaySession = ApplePayConfigurator.createApplePaySession(
				merchantInfo.merchantName!,
				checkoutData.amount!,
				APPLE_PAY_VERSION
			);
			context.commit(Mutations.ECOM__SET_APPLE_PAY_SESSION_OBJECT, applePaySession);
		},
		[Actions.ECOM__ADD_APPLE_PAY](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			rootElements: HtmlRootElementsCollection
		) {
			let APayScriptTag: HTMLScriptElement | any = document.getElementById(rootElements.scriptElementId!);

			if (!APayScriptTag) {
				APayScriptTag = document.createElement("script");
				APayScriptTag.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
				APayScriptTag.id = rootElements.scriptElementId;
				document.getElementsByTagName("head")[0].appendChild(APayScriptTag);

				APayScriptTag.onload = () => {
					context.dispatch(Actions.ECOM__SETUP_APPLE_PAY_BUTTON, rootElements.buttonElementId!);
					context.commit(Mutations.ECOM__SET_IS_APPLE_PAY_METHOD_READY, true);
				};
				APayScriptTag.onerror = () => context.commit(Mutations.ECOM__SET_IS_APPLE_PAY_METHOD_READY, true);
			} else {
				context.dispatch(Actions.ECOM__SETUP_APPLE_PAY_BUTTON, rootElements.buttonElementId!);
			}
		},
		[Actions.ECOM__SETUP_APPLE_PAY_BUTTON](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			rootElementId: string
		) {
			ApplePayConfigurator.initialize(rootElementId);
		},
		[Actions.ECOM__ADD_BVR_PAY](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			configuration: BvrPayConfiguration
		) {
			if (!configuration.rootElements) {
				console.error("BvrPay root element(s) is not set");
				return;
			}

			let BvrPayScriptTag: HTMLScriptElement | any = document.getElementById(
				configuration.rootElements.scriptElementId!
			);
			const checkoutData: EcomCheckoutData = context.getters[Getters.ECOM__GET_CHECKOUT_DATA];

			if (!checkoutData.phoneNumber!) {
				context.commit(Mutations.ECOM__SET_IS_BVR_PAY_METHOD_READY, true);
				return;
			}

			const initBvrPayButton = () => {
				const ecomPay: IBvrSdk = SdkLoader.loadCdnSdk();

				ecomPay.phoneNumber = checkoutData.phoneNumber!.toString();
				ecomPay.amount = checkoutData.amount;
				ecomPay.description = checkoutData.description;
				ecomPay.partnerOrderId = checkoutData.partnerOrderId;
				ecomPay.merchantId = checkoutData.merchantId;
				ecomPay.authType = checkoutData.authType;
				ecomPay.successRedirectUrl = checkoutData.successRedirectUrl;
				ecomPay.failureRedirectUrl = checkoutData.failureRedirectUrl;
				ecomPay.callbackUrl = checkoutData.callBackUrl;
				ecomPay.cultureName = checkoutData.cultureName;
				ecomPay.signature = checkoutData.signature;
				ecomPay.keyHash = checkoutData.keyHash;
				ecomPay.customParameters = JSON.parse(checkoutData.customParameters!);
				context.commit(Mutations.ECOM__SET_IS_BVR_PAY_METHOD_READY, true);
			};

			try {
				initBvrPayButton();
			} catch {
				if (!BvrPayScriptTag) {
					const timestamp = new Date().getTime();

					BvrPayScriptTag = document.createElement("script");
					BvrPayScriptTag.id = "bvrpay";
					BvrPayScriptTag.src = `${configuration.ecomSdkUrl}?timestamp=${timestamp}`; // prevent script caching
					BvrPayScriptTag.onload = () => initBvrPayButton();
					BvrPayScriptTag.onerror = () => context.commit(Mutations.ECOM__SET_IS_BVR_PAY_METHOD_READY, true);
					document.getElementsByTagName("head")[0].appendChild(BvrPayScriptTag);
				}
			}
		},
		/* istanbul ignore next */
		[Actions.ECOM__ADD_GOOGLE_PAY](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			configuration: GooglePayConfiguration
		) {
			if (!configuration.rootElements) {
				console.error("Root element(s) for Google Pay button is not set");
				return;
			}
			let GPayScriptTag: HTMLScriptElement | any = document.getElementById(configuration.rootElements.scriptElementId!);
			const merchantInfo: MerchantInfoCache = context.getters[Getters.ECOM__GET_MERCHANT_INFO];
			const checkoutData: EcomCheckoutData = context.getters[Getters.ECOM__GET_CHECKOUT_DATA];

			const initGooglePayButton = () => {
				const configurator = new GooglePayConfigurator(
					configuration.rootElements!.buttonElementId!,
					checkoutData.merchantId!
				);
				configurator.countryCode = "UA";
				configurator.currencyCode = "UAH";
				configurator.totalPriceStatus = "FINAL";
				configurator.merchantName = merchantInfo.merchantName!;
				configurator.totalPrice = checkoutData.amount!.toString();
				// @ts-expect-error
				const paymentsClient = new google.payments.api.PaymentsClient({ environment: configuration.mode });
				configurator.initialize(paymentsClient, (token: string) => {
					const request: GooglePayRequest = new GooglePayRequest({ googlePayToken: token });
					context.dispatch(Actions.ECOM__SEND_GOOGLE_PAY_APPROVE_REQUEST, request);
				});
				context.commit(Mutations.ECOM__SET_IS_GOOGLE_PAY_METHOD_READY, true);
			};

			try {
				initGooglePayButton();
			} catch {
				if (!GPayScriptTag) {
					const timestamp = new Date().getTime();

					GPayScriptTag = document.createElement("script");
					GPayScriptTag.id = configuration.rootElements.scriptElementId;
					GPayScriptTag.src = `https://pay.google.com/gp/p/js/pay.js?timestamp=${timestamp}`;
					GPayScriptTag.onload = () => initGooglePayButton();
					GPayScriptTag.onerror = () => context.commit(Mutations.ECOM__SET_IS_GOOGLE_PAY_METHOD_READY, true);
					document.getElementsByTagName("head")[0].appendChild(GPayScriptTag);
				}
			}
		},
		async [Actions.ECOM__LOAD_CHECKOUT_DATA](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			checkoutId: string
		): Promise<void> {
			context.commit(Mutations.ECOM__SET_IS_APP_WAITING, true);

			const requestUrl = `/api/CardPayCheckout?checkoutId=${checkoutId}`;

			await HttpRequestDispatcher.SendRequest(HttpRequestMethod.GET, requestUrl)
				.then((response: any) => {
					if (!response || !response.data) {
						router.push("/");
						return;
					}

					const applicationCache: EcomApplicationCache = response.data;
					const ecomCheckoutCache: EcomCheckoutCache = applicationCache.ecomCheckoutCache!;
					const merchantInfoCache: MerchantInfoCache = applicationCache.merchantInfoCache!;
					const authResult = ecomCheckoutCache.ecomCheckoutData!.authResultReady;

					context.commit(Mutations.ECOM__SET_CHECKOUT_DATA, ecomCheckoutCache.ecomCheckoutData);

					const checkoutDataHandlingErrorCode = ecomCheckoutCache.ecomCheckoutData?.checkoutDataHandlingErrorCode;
					if(checkoutDataHandlingErrorCode !== ErrorCode.Success) {
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, checkoutDataHandlingErrorCode);
						context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						return;
					}

					context.commit(Mutations.ECOM__SET_MERCHANT_INFO, merchantInfoCache);
					context.commit(Mutations.ECOM__SET_IS_ORDER_NUMBER_HIDDEN, merchantInfoCache.isHiddenPartnerOrderId);
					context.commit(Mutations.ECOM__SET_IS_CONFIRMATION_WAITING, ecomCheckoutCache.isPaymentConfirmExpected);
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
					context.commit(
						Mutations.ECOM__SET_AVAILABLE_PAYMENT_ATTEMPTS,
						ecomCheckoutCache.paymentConfirmationAttemptsLeft
					);

					if (!merchantInfoCache) {
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, ErrorCode.MerchantDataIsNull);
						context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						return;
					}

					if (ecomCheckoutCache.isPayedOrder) {
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, ErrorCode.IsPayedOrder);
						context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
						return;
					}

					if (authResult && authResult !== "Y") {
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, ErrorCode.ThreeDSecureAuthEMV3DSv2Error);
						context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);

						return;
					}

					if (applicationCache.ecomCheckoutCache?.isPaymentConfirmExpected) {
						context.dispatch(Actions.ECOM__CONFIRM_CHALLANGE_FLOW_PAYMENT, checkoutId);
					}
				})
				.catch((error: any) => {
					if (error.response.status === 404) {
						router.push("/");
					} else {
						console.error(error);
						router.push("/Checkout/error");
					}
					console.error(error);
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				});
		},
		[Actions.ECOM__REDIRECT_TO_STORE](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			isUnhandledError: boolean
		) {
			const checkoutData = context.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const errorcode = context.getters[Getters.ECOM__GET_PAYMENT_ERROR];
			const merchantInfo: MerchantInfoCache = context.getters[Getters.ECOM__GET_MERCHANT_INFO];

			if (!checkoutData) {
				console.error("Checkout data is not provided");
				return;
			}

			const failureRedirectUrl = checkoutData.failureRedirectUrl
				? checkoutData.failureRedirectUrl
				: merchantInfo.defaultFailureRedirectUrl;
			const successRedirectUrl = checkoutData.successRedirectUrl
				? checkoutData.successRedirectUrl
				: merchantInfo.defaultSuccessRedirectUrl;

			if ((errorcode && errorcode !== ErrorCode.Success) || isUnhandledError) {
				if (!failureRedirectUrl) {
					console.error("The failure redirect URL is not provided");
					return;
				}
				UrlRedirectResolver.redirectToUrl(failureRedirectUrl);
			} else {
				if (!successRedirectUrl) {
					console.error("The success redirect URL is not provided");
					return;
				}
				UrlRedirectResolver.redirectToUrl(successRedirectUrl);
			}
		},
		async [Actions.ECOM__SEND_GOOGLE_PAY_APPROVE_REQUEST](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			request: GooglePayRequest
		) {
			context.commit(Mutations.ECOM__SET_IS_APP_WAITING, true);

			const checkoutData: EcomCheckoutData = context.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const requestUrl = "/api/GooglePayApprove";
			request.browserInfo = await BrowserInfoHelper.getBrowserInfo();
			Object.assign(request, checkoutData);

			await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl, request)
				.then((response: any) => {
					if (!response || !response.data) {
						console.error("Google pay approve response or response data is null");
						router.push("/Checkout/error");
						return;
					}

					const data: GooglePayResponse = response.data;
					const googlePayApproveResponse: GooglePayApproveResponse = data.googlePayApproveResponse!;

					if (!googlePayApproveResponse) {
						console.error("GooglePayApproveResponse data is null");
						router.push("/Checkout/error");
					}

					if (googlePayApproveResponse!.errorCode !== ErrorCode.Success) {
						if (googlePayApproveResponse!.errorCode === ErrorCode.MaxApproveAttemptsExceeded) {
							context.commit(
								Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN,
								EcomCheckoutScreen.PaymentAttemptsLimitRiched
							);
						} else {
							context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						}
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, googlePayApproveResponse.errorCode);
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
						return;
					}

					context.commit(Mutations.ECOM__SET_AVAILABLE_PAYMENT_ATTEMPTS, data.availablePaymentAttempts);

					if (
						googlePayApproveResponse.check3DS === ThreeDSecure.V2 &&
						data.googlePayApproveResponse!.flow! === ThreeDSecureFlow.Challenge
					) {
						context.dispatch(CommonActions.COMMON__CLOSE_WEB_SOCKET_CONNECTION);
						context.dispatch(Actions.ECOM_GO_TO_LINK, googlePayApproveResponse!.acsUrl);
					}
				})
				.catch(e => {
					console.error(e);
					router.push("/Checkout/error");
				})
				.finally(() => {
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				});
		},
		async [Actions.ECOM__SEND_CARD_PAY_APPROVE_REQUEST](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			request: CardDetails
		) {
			context.commit(Mutations.ECOM__SET_IS_APP_WAITING, true);

			const checkoutData: EcomCheckoutData = context.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const requestUrl = "/api/CardPayApprove";
			const cardPayApproveRequest = new CardPayData({
				browserInfo: await BrowserInfoHelper.getBrowserInfo(),
				cardDetails: request,
			});

			Object.assign(cardPayApproveRequest, checkoutData);

			await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl, cardPayApproveRequest)
				.then((response: any) => {
					if (!response || !response.data) {
						console.error("Card pay approve response or response data is null");
						router.push("/Checkout/error");
						return;
					}

					const data: CardPayResponse = response.data;
					const cardPayApproveResponse = data.cardPayApproveResponse;

					if (!cardPayApproveResponse) {
						console.error("CardPayConfirmResponse data is null");
						router.push("/Checkout/error");
					}

					if (cardPayApproveResponse!.errorCode !== ErrorCode.Success) {
						if (cardPayApproveResponse!.errorCode === ErrorCode.MaxApproveAttemptsExceeded) {
							context.commit(
								Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN,
								EcomCheckoutScreen.PaymentAttemptsLimitRiched
							);
						} else {
							context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentCancel);
						}
						context.commit(Mutations.ECOM__SET_PAYMENT_ERROR, cardPayApproveResponse!.errorCode);
						context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
						return;
					}

					context.commit(Mutations.ECOM__SET_AVAILABLE_PAYMENT_ATTEMPTS, data.availablePaymentAttempts);

					if (
						cardPayApproveResponse!.check3DS === ThreeDSecure.V2 &&
						data.cardPayApproveResponse!.flow === ThreeDSecureFlow.Challenge
					) {
						context.dispatch(CommonActions.COMMON__CLOSE_WEB_SOCKET_CONNECTION);
						context.dispatch(Actions.ECOM_GO_TO_LINK, cardPayApproveResponse!.acsUrl);
					}
				})
				.catch(e => {
					console.error(e);
					router.push("/Checkout/error");
				})
				.finally(() => {
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				});
		},
		[Actions.ECOM_GO_TO_LINK](context: ActionContext<IEcomCheckoutState, IRootState>, url: string) {
			window.open(url, "_self");
		},
		async [Actions.SEND_RECEIPT_TO_EMAIL](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			payload: CardPaySendReceiptPayload
		) {
			const requestUrl = "/api/receipt/send";
			context.commit(Mutations.ECOM__SET_IS_APP_WAITING, true);
			await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl, payload)
				.then(() => {
					context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.ReceiptSendSuccess);
				})
				.catch(() => {
					context.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.ReceiptSendError);
				})
				.finally(() => {
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				});
		},
		async [Actions.ECOM__CONFIRM_CHALLANGE_FLOW_PAYMENT](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			checkoutId: string
		) {
			context.commit(Mutations.ECOM__SET_IS_APP_WAITING, true);
			const requestUrl = `/api/CardPayConfirm/ConfirmPayment/${checkoutId}`;

			await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl)
				.then(() => {
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				})
				.catch(e => {
					console.error(e);
					context.commit(Mutations.ECOM__SET_IS_APP_WAITING, false);
				});
		},
		async [Actions.ECOM__SEND_USER_ACTION_CODE](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			request: ActionLogRequest
		) {
			const requestUrl = "/api/action/sendLog";

			try {
				await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl, request);
			} catch (err) {
				if (err instanceof Error) {
					console.error(`User ActionLog send error: ${err.message}`);
				} else {
					console.error(err);
				}
			}
		},
		async [Actions.ECOM__GET_CARD_INFORMATION](
			context: ActionContext<IEcomCheckoutState, IRootState>,
			request: ActionLogRequest
		) {
			const requestUrl = "/api/CardInformation/get";

			try {
				const response: AxiosResponse = await HttpRequestDispatcher.SendRequest(
					HttpRequestMethod.POST,
					requestUrl,
					request
				);

				const responseData: CardInformationResponse = response.data;

				return responseData;
			} catch (err) {
				if (err instanceof Error) {
					console.error(`Get card information error: ${err.message}`);
				} else {
					console.error(err);
				}
			}
		},
	},
};
