export const ECOM__LOAD_CHECKOUT_DATA = "ECOM__LOAD_CHECKOUT_DATA";
export const ECOM__REDIRECT_TO_STORE = "ECOM__REDIRECT_TO_STORE";
export const ECOM_GO_TO_LINK = "ECOM_GO_TO_LINK";
export const SEND_RECEIPT_TO_EMAIL = "SEND_RECEIPT_TO_EMAIL";
export const ECOM__SEND_CARD_PAY_APPROVE_REQUEST = "ECOM__SEND_CARD_PAY_APPROVE_REQUEST";
export const ECOM__SEND_GOOGLE_PAY_APPROVE_REQUEST = "ECOM__SEND_GOOGLE_PAY_APPROVE_REQUEST";
export const ECOM__SETUP_BVR_PAY_BUTTON = "ECOM__SETUP_BVR_PAY_BUTTON";
export const ECOM__SETUP_GOOGLE_PAY_BUTTON = "ECOM__SETUP_GOOGLE_PAY_BUTTON";
export const ECOM__SETUP_APPLE_PAY_BUTTON = "ECOM__SETUP_APPLE_PAY_BUTTON";
export const ECOM__ADD_APPLE_PAY = "ECOM__ADD_APPLE_PAY";
export const ECOM__ADD_GOOGLE_PAY = "ECOM__ADD_GOOGLE_PAY";
export const ECOM__ADD_BVR_PAY = "ECOM__ADD_BVR_PAY";
export const ECOM__CREATE_APPLE_PAY_SESSION = "ECOM__CREATE_APPLE_PAY_SESSION";
export const ECOM__VALIDATE_APPLE_PAY_SESSION = "ECOM__VALIDATE_APPLE_PAY_SESSION";
export const ECOM__PERFORM_APPLE_PAY_PAYMENT = "ECOM__PERFORM_APPLE_PAY_PAYMENT";
export const ECOM__COMPLETE_APPLE_PAYMENT = "ECOM__COMPLETE_APPLE_PAYMENT";
export const ECOM__SEND_APPLE_PAY_APPROVE_REQUEST = "ECOM__SEND_APPLE_PAY_APPROVE_REQUEST";
export const ECOM__LOAD_CHECKOUT_CONFIGURATION = "ECOM__LOAD_CHECKOUT_CONFIGURATION";
export const ECOM__CONFIRM_CHALLANGE_FLOW_PAYMENT = "ECOM__CONFIRM_CHALLANGE_FLOW_PAYMENT";
export const ECOM__SEND_USER_ACTION_CODE = "ECOM__SEND_USER_ACTION_CODE";
export const ECOM__GET_CARD_INFORMATION = "ECOM__GET_CARD_INFORMATION";
