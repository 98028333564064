export enum OperationSystem {
	WINDOWS,
	LINUX,
	MAC,
	IOS,
	ANDROID,
	BLACKBERRY,
}

export class PlatformDetector {
	public static GetCurrentPatform(): OperationSystem | null {
		const platform = navigator.platform;

		switch (platform) {
			case "Linux i686":
				return OperationSystem.LINUX;
			case "Linux armv7l":
				return OperationSystem.LINUX;

			case "Mac68K":
				return OperationSystem.MAC;
			case "MacPPC":
				return OperationSystem.MAC;
			case "MacIntel":
				return OperationSystem.MAC;

			case "Win16":
				return OperationSystem.WINDOWS;
			case "Win32":
				return OperationSystem.WINDOWS;
			case "WinCE":
				return OperationSystem.WINDOWS;

			case "iPhone":
				return OperationSystem.IOS;
			case "iPod":
				return OperationSystem.IOS;
			case "iPad":
				return OperationSystem.IOS;

			case "Android":
				return OperationSystem.ANDROID;

			case "BlackBerry":
				return OperationSystem.BLACKBERRY;

			default:
				return null;
		}
	}
}
