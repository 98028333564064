export const ECOM__SET_CURRENT_CHECKOUT_SCREEN = "ECOM__SET_CURRENT_CHECKOUT_SCREEN";
export const ECOM__SET_CHECKOUT_DATA = "ECOM__SET_CHECKOUT_DATA";
export const ECOM__SET_MERCHANT_INFO = "ECOM__SET_MERCHANT_INFO";
export const ECOM__SET_IS_APP_WAITING = "ECOM__SET_IS_APP_WAITING";
export const ECOM__SET_IS_CONFIRMATION_WAITING = "ECOM__SET_IS_CONFIRMATION_WAITING";
export const ECOM__SET_PAYMENT_ERROR = "ECOM__SET_PAYMENT_ERROR";
export const ECOM__SET_APPLE_PAY_SESSION_OBJECT = "ECOM_SET_APPLE_PAY_SESSION_OBJECT";
export const ECOM__SET_CHECKOUT_CONFIGURATION = "ECOM__SET_CHECKOUT_CONFIGURATION";
export const ECOM__SET_AVAILABLE_PAYMENT_ATTEMPTS = "ECOM__SET_AVAILABLE_PAYMENT_ATTEMPTS";
export const ECOM__SET_IS_GOOGLE_PAY_METHOD_READY = "ECOM__SET_IS_GOOGLE_PAY_METHOD_READY";
export const ECOM__SET_IS_BVR_PAY_METHOD_READY = "ECOM__SET_IS_BVR_PAY_METHOD_READY";
export const ECOM__SET_IS_APPLE_PAY_METHOD_READY = "ECOM__SET_IS_APPLE_PAY_METHOD_READY";
export const ECOM__SET_IS_ORDER_NUMBER_HIDDEN = "ECOM__SET_IS_ORDER_NUMBER_HIDDEN";
