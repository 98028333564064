import GPayMethod from "@/models/googlePay/GPayMethod";

export default class GPayCardPaymentMethod extends GPayMethod {
	public tokenizationSpecification?: GPayMethod;

	constructor(payMethod: GPayMethod, tokenizationSpecification: GPayMethod) {
		super(payMethod);
		this.tokenizationSpecification = tokenizationSpecification;
	}
}
