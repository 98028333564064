import BrowserInfo from "@/models/BrowserInfo";

export default class BrowserInfoHelper {
	public static getBrowserInfo(): BrowserInfo {
		return new BrowserInfo({
			browserColorDepth: this.getColorDepthValue(),
			browserLanguage: navigator.language,
			browserScreenHeight: screen.height,
			browserScreenWidth: screen.width,
			browserTZ: new Date().getTimezoneOffset(),
			browserUserAgent: navigator.userAgent,
			browserJavaEnabled: "N",
			browserJavascriptEnabled: "Y",
			browserAcceptHeader: "application/json, text/plain, */*",
		});
	}

	private static getColorDepthValue(): number {
		const colorDepth = screen.colorDepth;
		const allowedColorDepthList = [1, 4, 8, 15, 16, 24, 32, 48];

		if (allowedColorDepthList.indexOf(colorDepth) !== -1) {
			return colorDepth;
		}

		let closestArrayElement;
		let minDifference;

		allowedColorDepthList.forEach(element => {
			const difference = Math.abs(colorDepth - element);

			if (!minDifference) {
				minDifference = difference;
			}

			if (difference < minDifference) {
				minDifference = difference;
				closestArrayElement = element;
			}
		});

		return closestArrayElement;
	}
}
