import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "header row items-center justify-between",
  id: "checkout-header"
}
const _hoisted_2 = {
  class: "header-logo-wrapper",
  id: "header-logo-wrapper"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "header-contacts",
  id: "header-contacts"
}
const _hoisted_5 = { class: "header-contacts-popup q-px-lg q-py-md" }
const _hoisted_6 = { class: "row no-wrap items-center justify-between q-mb-lg" }
const _hoisted_7 = {
  class: "social-icons-wrapper row no-wrap justify-evenly q-pa-lg",
  id: "social-icons-wrapper"
}
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = {
  href: "https://t.me/bankvostokhelpbot",
  target: "_blank",
  rel: "noopener noreferrer",
  id: "header-telegram-link"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "text-center" }
const _hoisted_12 = {
  href: "https://bankvostok.com.ua/_help_viber",
  target: "_blank",
  rel: "noopener noreferrer",
  id: "header-viber-link"
}
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/vostok-logo.svg'),
        width: "79",
        height: "20",
        id: "checkout-header-logo",
        class: "header-logo",
        alt: "Логотип Vostok"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[10] || (_cache[10] = _createElementVNode("p", {
        class: "header-contacts-text",
        id: "header-contacts-general-outer-text",
        style: {"margin-bottom":"2px"}
      }, " Безкоштовно по Україні ", -1)),
      _createElementVNode("div", {
        class: "row items-center cursor-pointer",
        id: "problems-area",
        title: "Виникли запитання?",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePopupState(true)))
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("p", {
          class: "header-contacts-phone q-mb-none q-mr-xs",
          id: "header-contacts-general-outer-phone"
        }, "0 800 30 70 10", -1)),
        _createVNode(_component_q_icon, {
          name: _ctx.isOpenPopup ? 'expand_less' : 'expand_more',
          size: "20px",
          color: "grey-6",
          id: "contacts-icon"
        }, null, 8, ["name"]),
        _createVNode(_component_q_dialog, {
          modelValue: _ctx.isOpenPopup,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpenPopup) = $event)),
          onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePopupState(false))),
          id: "contacts-popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[4] || (_cache[4] = _createElementVNode("p", {
                  class: "header-contacts-popup-title q-mb-none ellipsis",
                  id: "header-contacts-popup-title"
                }, " Виникли запитання? ", -1)),
                _createVNode(_component_q_btn, {
                  flat: "",
                  round: "",
                  icon: "close",
                  class: "header-contacts-popup-close",
                  id: "header-contacts-popup-close",
                  color: "grey-6",
                  padding: "xs",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePopupState(false)))
                })
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "q-mb-md" }, [
                _createElementVNode("a", {
                  href: "tel:0800307010",
                  class: "header-contacts-phone text-no-wrap",
                  title: "Зателефонувати",
                  id: "header-general-inner-phone"
                }, "0 800 30 70 10"),
                _createElementVNode("p", {
                  class: "header-contacts-text",
                  id: "header-general-inner-text"
                }, "Безкоштовно по Україні")
              ], -1)),
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "q-mb-lg" }, [
                _createElementVNode("a", {
                  href: "tel:0443937010",
                  class: "header-contacts-phone text-no-wrap",
                  title: "Зателефонувати",
                  id: "header-int-inner-phone"
                }, "044 393 70 10"),
                _createElementVNode("p", {
                  class: "header-contacts-text",
                  id: "header-int-inner-text"
                }, "Для міжнародних дзвінків")
              ], -1)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("a", _hoisted_9, [
                    _createElementVNode("img", {
                      src: require('@/assets/images/checkout-telegram.svg'),
                      class: "social-icons-image q-mb-sm",
                      width: "54",
                      height: "54",
                      title: "Відкрити бот Telegram",
                      alt: "Бот Telegram",
                      id: "header-telegram-icon"
                    }, null, 8, _hoisted_10)
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("p", {
                    class: "social-icons-text no-margin",
                    id: "header-telegram-text"
                  }, "Telegram", -1))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("a", _hoisted_12, [
                    _createElementVNode("img", {
                      src: require('@/assets/images/checkout-viber.svg'),
                      class: "social-icons-image q-mb-sm",
                      width: "54",
                      height: "54",
                      title: "Відкрити бот Viber",
                      alt: "Бот Viber",
                      id: "header-viber-icon"
                    }, null, 8, _hoisted_13)
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("p", {
                    class: "social-icons-text no-margin",
                    id: "header-viber-text"
                  }, "Viber", -1))
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ]))
}